import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { Container as ModalContainer } from "react-modal-promise";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 20 * 1000,
    },
    mutations: {
      retry: false,
    },
  },
});

const RootElement = ({ children }: { children: React.ReactNode }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ModalContainer />
      {children}
    </QueryClientProvider>
  );
};

export default RootElement;
