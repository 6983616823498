import "./src/styles/global.css";
import "swiper/css";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React from "react";
import SwiperCore, { Autoplay, EffectCreative } from "swiper";

import RootElement from "./src/components/RootElement";

SwiperCore.use([Autoplay, EffectCreative]);

gsap.registerPlugin(ScrollTrigger);
gsap.config({
  force3D: true,
});

ScrollTrigger.config({
  ignoreMobileResize: true,
  autoRefreshEvents: "visibilitychange,DOMContentLoaded,load",
});

export const wrapRootElement = ({ element }) => {
  return <RootElement>{element}</RootElement>;
};
